import React from "react";
import styled from "styled-components";

import Image from "../components/image";
import Button from "../components/button";

const Contact = styled.div``;

const ContactLink = styled.a`
  display: block;
  margin-top: 0.5rem;
  text-decoration: none;
  :first-of-type {
    margin-top: 0;
  }
`;

const StyledImage = styled(Image)`
  display: inline-block;
  border-radius: 5px;
  max-height: 400px;
  @media (max-width: 768px) {
    max-height: none;
    max-width: 100%;
  }
`;

const ContactContainer = ({ portrait }) => {
  console.log("portrait 2", portrait);
  return (
    <Contact>
      <StyledImage src={portrait} alt="Portrait of Brooke Nicholas" />
      <ContactLink href="mailto:brooke.is.nicholas@gmail.com">
        <Button>brooke.is.nicholas@gmail.com</Button>
      </ContactLink>
      <ContactLink
        href="https://www.instagram.com/broooohaha/"
        target="_blank"
        rel="noreferrer"
      >
        <Button>@broooohaha</Button>
      </ContactLink>
    </Contact>
  );
};

export default ContactContainer;
