import React, { Fragment } from "react";
import { graphql } from "gatsby";

import Seo from "../../components/seo";

import Contact from "../../containers/contact";

const ContactPage = ({ data }) => {
  const {
    allFile: {
      edges: [
        {
          node: {
            childMarkdownRemark: {
              frontmatter: { portrait },
            },
          },
        },
      ],
    },
  } = data;
  return (
    <Fragment>
      <Seo title="Contact" />
      <Contact portrait={portrait} />
    </Fragment>
  );
};

export const projectQuery = graphql`
  query ContactPageQuery {
    allFile(
      filter: { sourceInstanceName: { eq: "files" }, name: { eq: "homepage" } }
    ) {
      edges {
        node {
          id
          childMarkdownRemark {
            frontmatter {
              portrait
            }
          }
        }
      }
    }
  }
`;

export default ContactPage;
